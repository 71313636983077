import React, { useState, useEffect } from 'react';
import './App.css';
import logo1 from './images/lords.png';
import logo2 from './images/pesco.png';
import logo3 from './images/grug.png';
import logo4 from './images/miggles.png';
import logo5 from './images/moby.png';
import logo6 from './images/allbase.png';

// Import Coinbase Wallet SDK and Web3
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';
import Web3 from 'web3';

function App() {
  const [inputValue, setInputValue] = useState(''); // Controlled input state
  const [errorMessage, setErrorMessage] = useState('');
  const [walletAddress, setWalletAddress] = useState('');
  const [isConnected, setIsConnected] = useState(false);

  // Coinbase Wallet SDK setup
  const APP_NAME = 'Based Cartel';
  const APP_LOGO_URL = 'https://www.basedcartel.life/logo.png'; // Replace with your logo URL
  const DEFAULT_ETH_JSONRPC_URL = 'https://mainnet.infura.io/v3/a482124e9ec74155b35bdf03c623376f'; // Replace with your Infura Project ID
  const DEFAULT_CHAIN_ID = 1; // Ethereum mainnet

  const wallet = new CoinbaseWalletSDK({
    appName: APP_NAME,
    appLogoUrl: APP_LOGO_URL,
    darkMode: false,
  });

  const ethereum = wallet.makeWeb3Provider(DEFAULT_ETH_JSONRPC_URL, DEFAULT_CHAIN_ID);
  const web3 = new Web3(ethereum);

  // Connect to the wallet and set the address
  const connectWallet = async () => {
    try {
      // Request wallet access
      const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
      const walletAddr = accounts[0];

      // Update the state with the wallet address
      setWalletAddress(walletAddr);
      setInputValue(walletAddr); // Ensure inputValue is set to the wallet address
      setIsConnected(true);

      // Force the input field to reflect the auto-populated address in the DOM
      const inputField = document.getElementById('wallet-input');
      if (inputField) {
        inputField.value = walletAddr;
        inputField.dispatchEvent(new Event('input', { bubbles: true })); // Trigger React's onChange
      }
    } catch (error) {
      console.error('Error connecting to Coinbase Wallet:', error);
    }
  };

  // Ensure input field is updated if wallet address changes
  useEffect(() => {
    if (walletAddress) {
      setInputValue(walletAddress); // Ensure the form input reflects the wallet address
    }
  }, [walletAddress]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    setErrorMessage('');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Ensure the wallet address is valid
    if (!inputValue.startsWith('0x')) {
      setErrorMessage('Input must be a valid wallet address');
      return;
    }

    // Try submitting the input (wallet address) to the database
    try {
      const response = await fetch('https://www.basedcartel.life/api/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ input: inputValue }), // Send the wallet address
      });

      if (response.ok) {
        setErrorMessage('Successfully submitted!');
        setInputValue(''); // Clear the input after submission
      } else {
        alert('Failed to submit input.');
      }
    } catch (error) {
      console.error('Error submitting input:', error);
    }
  };

  return (
    <div className="app-container">
      <header>
        <h1>Based Cartel Allowlist</h1>
      </header>

      {/* Wallet Connect button or connected status */}
      <div className="wallet-connect-button">
        {!isConnected ? (
          <button onClick={connectWallet}>Connect Coinbase Wallet</button>
        ) : (
          <span className="connected-status">Connected</span> // Display "Connected" when wallet is connected
        )}
      </div>

      <main>
        <form className="input-form" onSubmit={handleSubmit}>
          <input
            id="wallet-input" // Set an ID for the input field to manipulate directly
            type="text"
            placeholder="Enter your wallet address"
            value={inputValue}  // Controlled input field
            onChange={handleInputChange} // Ensure React handles changes
          />
          <button type="submit">Submit</button>
        </form>
        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        <p className="collaboration-text">In Collaboration With</p>

        <div className="collaboration-images">
          <img src={logo1} alt="Logo 1" />
          <img src={logo2} alt="Logo 2" />
          <img src={logo3} alt="Logo 3" />
          <img src={logo4} alt="Logo 4" />
          <img src={logo5} alt="Logo 5" />
          <img src={logo6} alt="Logo 6" />
        </div>
      </main>
    </div>
  );
}

export default App;
